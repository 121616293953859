import axios from "axios";
import { defaultResponse, mergeConfigs } from "./apiRequestUtils";

/**
 * PUT requests
 * @param {string} url - api request url (including any query strings)
 * @param {object} payload - data to save
 * @param {object} additionalConfig - any additional configurations for requests
 * @returns { data, error } object with data from API in data key, and any error in error key
 */
const putAsyncRequest = async (url = "", payload = {}, additionalConfig = {}) => {
  const config = mergeConfigs(additionalConfig);
  const response = { ...defaultResponse };
  try {
    const axiosResponse = await axios.put(url, payload, config);
    if (axiosResponse) {
      const { data, status, statusText } = axiosResponse;
      response.data = data;
      response.status = status;
      response.statusText = statusText;
      response.headers = axiosResponse.headers;
    }
  } catch (error) {
    if (error.response) {
      response.data = error.response.data;
      response.status = error.response.status;
    } else {
      response.data = error;
    }
  }
  return response;
};

export default putAsyncRequest;
