import { Tab, Tabs } from "@mui/material";
import { Dialog } from "../dialog";
import styles from "./quick-start-popper.styles.module.scss";
import React, { ReactElement, useEffect, useState } from "react";
import classNames from "classnames";

type QuickStartPopperComponentProps = {
  isOpen: boolean;
  onClose: () => void;
  tabsData: {
    label: string;
    content: ReactElement;
  }[];
  onTabClick: (tabIndex: number) => void;
  title: string;
};

export const QuickStartPopperComponent = (props: QuickStartPopperComponentProps) => {
  const MAX_DIALOG_WIDTH = "1158px";
  const [selectedTab, setSelectedTab] = useState(0);
  const { tabsData, title, onTabClick, onClose, isOpen } = props;

  useEffect(() => {
    return () => {
      setSelectedTab(0);
    };
  }, [isOpen]);

  const dialogContent = (
    <div className={classNames(styles.tabsContainer)}>
      <Tabs
        TabIndicatorProps={{
          className: styles.tabIndicator,
        }}
        orientation="vertical"
        value={selectedTab}
        onChange={(event: React.SyntheticEvent, newValue: number) => {
          onTabClick(newValue);
          setSelectedTab(newValue);
        }}
        className={classNames(styles.tabs)}
      >
        {tabsData?.map((tab) => {
          return (
            <Tab
              disableRipple={true}
              disableFocusRipple={true}
              className={classNames(styles.tabLabel)}
              key={`tab-label-${tab.label}`}
              label={tab.label}
            />
          );
        })}
      </Tabs>
      {tabsData?.map((tab, index) => {
        return (
          <div key={"tab-content-" + index} role="tabpanel" hidden={selectedTab !== index}>
            {selectedTab === index && <div className={styles.tabPanel}>{tab.content} </div>}
          </div>
        );
      })}
      <div className={styles.bottomOverlay} />
    </div>
  );

  return (
    <Dialog
      rootClassName={styles.root}
      open={isOpen}
      title={title}
      body={dialogContent}
      maxDialogWidth={MAX_DIALOG_WIDTH}
      closeIcon={true}
      handleClose={onClose}
      closeIconClassName={styles.closeIcon}
      wrapperClassName={styles.dialogContainer}
    />
  );
};
